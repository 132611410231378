import { useState, useEffect } from "react";
import { Button, Icon, Label } from "semantic-ui-react";

// framework
import TabFramework from "react-lib/frameworks/Tab";

// UX
import CardDrugOrderQueue from "./CardDrugOrderQueue";
import CardDrugOrderHistory from "./CardDrugOrderHistory";
import CardDrugTransferRequestQueue from "./CardDrugTransferRequestQueue";
import CardDrugTransferRequestHistory from "./CardDrugTransferRequestHistory";
import CardDrugReturnRequestHistory from "./CardDrugReturnRequestHistory";
import CardMedicationErrorList from "./CardMedicationErrorList";
import CardMedicationErrorStatistics from "./CardMedicationErrorStatistics";
import CardORQueue from "../ORM/CardORQueue";
import CardDrugResuscitation from "./CardDrugResuscitation";
import CardPrintDrugLabelManual from "./CardPrintDrugLabelManual";
import CardDashboardTelephar from "./CardDashboardTelephar";
import CardIntraTelepharQueue from "./CardIntraTelepharQueue";
import CardUnverifiedAllergy from "react-lib/apps/HISV3/TPD/CardUnverifiedAllergy";

// Stock
import CardStockManagement from "./CardStockManagement";
import CardStockTransferOrder from "./CardStockTransferOrder";
import CardPHVWrapper from "react-lib/apps/HISV3/PHV/CardPHVWrapper";
import CardTelepharQueue from "./CardTelepharQueue";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

// Config
import CONFIG from "config/config";
import { useIntl } from "react-intl";

const BUTTON_ACTIONS = {
  print_rx_form: "print_prescription_form",
  print_medicine_form: "print_medicine_form",
};

const CARD_PHARMACY_TASK = "CardPharmacyTask";

const ACTION_PRINT_RX = `${CARD_PHARMACY_TASK}_${BUTTON_ACTIONS.print_rx_form}`;
const ACTION_PRINT_MEDICINE = `${CARD_PHARMACY_TASK}_${BUTTON_ACTIONS.print_medicine_form}`;

const CardPharmacyTask = (props: any) => {
  const intl = useIntl();
  console.log("Init CardPharmacyTask: ", props);
  const [mode, setMode] = useState("order");
  const [noti, setNoti] = useState(false);
  const [openModConfirmPrintMedicine, setOpenModConfirmPrintMedicine] = useState<boolean>(false);
  const [noHaveRequest, setNoHaveRequest] = useState(false);

  useEffect(() => {
    console.log("CardPharmacyTask useEffect Init   SelectEncounter !!! ");
    if (props.drugOrderTabMode) {
      setMode(props.drugOrderTabMode);
    } else {
      props.setProp("drugOrderTabMode", "order");
    }

    return () => {
      console.log("CardPharmacyTask Involve unregister SelectEncounter !!! ");
    };
  }, []);

  useEffect(() => {
    if (props?.verbalDrugOrderQueue?.length === 0) {
      setNoti(false);
    } else {
      setNoti(true);
    }
  }, [props.verbalDrugOrderQueue]);

  useEffect(() => {
    if (props.selectedDivision?.name_en === "Telephar" && CONFIG.ENABLE_TELEPHAR) {
      handleSetMode("dashboardTelephar");
    }
  }, [props.selectedDivision?.name_en]);

  // Todo: have filter IPD/OPD, patient search, ward, drug order status
  // Todo: add button to print for preparing drugs and delivering drugs

  // Todo: sort STAT to come first always, then continue plan, then continue issue 1st dose
  // Todo: add verbal order to CardDrugOrderQueue
  // Todo: default filter out pharmacy task finished
  // Todo: different color for waiting time

  // Todo: add drug order history for pharmacist to review in case of ad-hoc inquiry

  // Todo (inside tab) add lab result

  const handleSetMode = (menu: string) => {
    props.setProp("drugOrderTabMode", menu);
    setMode(menu);
  };

  const handlePrintPrescriptionForm = () => {
    props.runSequence({
      sequence: "SetDrugOrderQueue",
      action: "print_prescription_form",
      card: CARD_PHARMACY_TASK,
    });
  };

  const handlePrintMedicineForm = (
    bypass: { bypassRequest?: boolean; bypassCheckMedRec?: boolean } = {
      bypassRequest: false,
      bypassCheckMedRec: false,
    }
  ) => {
    const checkedList: number[] = props.SetDrugOrderQueueSequence?.checkedList || [];

    const data = checkedList.map((id) => props.drugOrderQueue?.find((acc: any) => id === acc.id));

    const isRequested = data.every((drug) => drug.status === "REQUESTED");
    const isNoRequested = data.every((drug) => drug.status !== "REQUESTED");

    console.log("handlePrintMedicineForm data: ", data);
    console.log("handlePrintMedicineForm isRequested: ", isRequested);

    if (isNoRequested) {
      setNoHaveRequest(true);
    } else {
      setNoHaveRequest(false);
    }

    if (!isRequested && !bypass?.bypassRequest) {
      return setOpenModConfirmPrintMedicine(true);
    }

    setOpenModConfirmPrintMedicine(false);

    props.runSequence({
      sequence: "SetDrugOrderQueue",
      action: "print_medicine_form",
      card: CARD_PHARMACY_TASK,
      bypassCheckMedRec: bypass.bypassCheckMedRec,
    });
  };

  const handleCloseModConfirmPrintMedicine = () => {
    setOpenModConfirmPrintMedicine(false);
  };

  const handleClose = () => {
    props.setProp("SetDrugOrderQueueSequence.drugPendingMedReconcile", null);
  };

  const handleContinue = () => {
    handlePrintMedicineForm({ bypassCheckMedRec: true });
  };

  console.log("CardPharmacyTask mode: ", mode);

  return (
    <div id="CardPharmacyTask" style={{ height: "100%", width: "100%", padding: "10px" }}>
      <div className="pharmacy-task-tabs" style={{ display: "flex" }}>
        {!CONFIG.HIDDEN_MENU_SERVICE_QUEUE ? (
          <MenuItem menu="order" mode={mode} name="Order Queue" setMode={handleSetMode} />
        ) : null}
        {CONFIG.ENABLE_INTRA_TELEPHAR ? (
          <MenuItem menu="intra" mode={mode} name="OPD Order Queue" setMode={handleSetMode} />
        ) : null}
        {CONFIG.ENABLE_TELEPHAR && (
          <MenuItem menu="dashboardTelephar" mode={mode} name="Dashboard" setMode={handleSetMode} />
        )}
        {!CONFIG.HIDDEN_MENU_SERVICE_QUEUE ? (
          <>
            <MenuItem
              menu="history"
              mode={mode}
              name="Drug History Search"
              setMode={handleSetMode}
            />
            <MenuItem menu="stock" mode={mode} name="Stock Management" setMode={handleSetMode} />
            <MenuItem menu="mederror" mode={mode} name="Medication Error" setMode={handleSetMode} />
            {/* <MenuItem menu="verbal" mode={mode} name={intl.formatMessage({ id: "รคส." })} setMode={handleSetMode} noti={noti} /> */}
          </>
        ) : null}
        {CONFIG.ENABLE_TELEPHAR && (
          <MenuItem menu="telaphar" mode={mode} name="Telephar" setMode={handleSetMode} />
        )}
        {!CONFIG.HIDDEN_MENU_SERVICE_QUEUE ? (
          <>
            <MenuItem menu="orschedule" mode={mode} name="OR Schedule" setMode={handleSetMode} />
            <MenuItem
              menu="patienthistoryviewer"
              mode={mode}
              name="Patient History Viewer"
              setMode={handleSetMode}
            />
            <MenuItem
              menu="unverifiedallergy"
              mode={mode}
              name="Unverified Allergy"
              setMode={handleSetMode}
            />
            <div style={{ flex: 1 }}></div>
            {/* Right */}
            {mode === "order" && (
              <>
                <Button
                  color="green"
                  size="tiny"
                  disabled={!props.SetDrugOrderQueueSequence?.checkedList?.length}
                  loading={!!props.buttonLoadCheck?.[ACTION_PRINT_MEDICINE]}
                  onClick={() => handlePrintMedicineForm()}
                >
                  พิมพ์ใบจัดยา
                </Button>
                <Button
                  color="green"
                  size="tiny"
                  disabled={!props.SetDrugOrderQueueSequence?.checkedList?.length}
                  loading={!!props.buttonLoadCheck?.[ACTION_PRINT_RX]}
                  onClick={handlePrintPrescriptionForm}
                >
                  พิมพ์ใบเตรียมจัดส่งยา
                </Button>
              </>
            )}

            <Button color="yellow" onClick={() => handleSetMode("print_drug_label")}>
              <Icon name="print" />
              พิมพ์ฉลากยา Manual
            </Button>
          </>
        ) : null}
      </div>
      {mode === "order" ? (
        <CardDrugOrderQueue
          ref={props.drugOrderQueueRef}
          onEvent={props.onEvent}
          drugOrderQueue={props.drugOrderQueue}
          verbalDrugOrderQueue={props.verbalDrugOrderQueue}
          masterOptions={props.masterOptions}
          runSequence={props.runSequence}
          setProp={props.setProp}
          SetDrugOrderQueueSequence={props.SetDrugOrderQueueSequence}
          controller={props.drugOrderQueueController}
          selectedDrugOrderWorking={props.selectedDrugOrderWorking}
          selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
          selectedDivision={props.selectedDivision}
          buttonLoadCheck={props.buttonLoadCheck}
          layout={props.layout}
          forward={props?.forward}
          errorMessage={props.errorMessage}
          // config
          hidePanel={false}
          isVerbal={false}
          height="57vh"
          languageUX={props.languageUX}
        />
      ) : mode === "intra" ? (
        <CardIntraTelepharQueue
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          IntraTelepharQueueSequence={props.IntraTelepharQueueSequence}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
          buttonLoadCheck={props.buttonLoadCheck}
          errorMessage={props.errorMessage}
          selectedDivision={props.selectedDivision}
          // options
          masterOptions={props.masterOptions}
          // callback
          forward={props?.forward}
          languageUX={props.languageUX}
        />
      ) : mode === "dashboardTelephar" ? (
        <CardDashboardTelephar
          onEvent={props.onEvent}
          runSequence={props.runSequence}
          masterOptions={props.masterOptions}
          masterData={props.masterData}
          layout={props.layout}
          forward={props?.forward}
          setProp={props.setProp}
          DashboardTelepharSequence={props.DashboardTelepharSequence}
          // Noti
          notificationMessageList={props.notificationMessageList}
          languageUX={props.languageUX}
        />
      // ) : mode === "verbal" ? (
      //   <CardDrugOrderQueue
      //     onEvent={props.onEvent}
      //     drugOrderQueue={props.drugOrderQueue}
      //     verbalDrugOrderQueue={props.verbalDrugOrderQueue}
      //     masterOptions={props.masterOptions}
      //     runSequence={props.runSequence}
      //     setProp={props.setProp}
      //     SetDrugOrderQueueSequence={props.SetDrugOrderQueueSequence}
      //     DrugOrderQueueSequence={props.DrugOrderQueueSequence}
      //     controller={props.drugOrderQueueController}
      //     selectedDrugOrderWorking={props.selectedDrugOrderWorking}
      //     selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
      //     buttonLoadCheck={props.buttonLoadCheck}
      //     layout={props.layout}
      //     forward={props?.forward}
      //     mode={mode}
      //     hidePanel={true}
      //     isVerbal={true}
      //     selectedDivision={props.selectedDivision}
      //     // Verbal
      //     searchedItemListWithKey={props.searchedItemListWithKey}
      //     languageUX={props.languageUX}
      //   />
      ) : mode === "history" ? (
        <div
          style={{
            padding: "1rem 5px 1.5rem",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <CardDrugOrderHistory
            onEvent={props.onEvent}
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            runSequence={props.runSequence}
            setProp={props.setProp}
            DrugOrderHistorySequence={props.DrugOrderHistorySequence}
            controller={props.drugOrderHistoryController}
            drugHistoryList={props.drugHistoryList}
            drugHistoryOrderList={props.drugHistoryOrderList}
            layout={props.layout}
            forward={props?.forward}
            //CardDrugOrderWorking
            selectedEncounter={props?.selectedEncounter}
            selectedEmr={props?.selectedEmr}
            drugOrderQueue={props?.drugOrderQueue}
            drugOrder={props?.selectedDrugOrderWorking}
            drugOrderLog={props?.drugOrderLog}
            modNoteReject={props?.modNoteReject}
            DrugSelectSequence={props?.DrugSelectSequence}
            selectedPatient={props?.selectedPatient}
            selectedDrug={props?.selectedDrug}
            drugDose={props?.drugDose}
            drugDelivery={props?.drugDelivery}
            modConfirmAction={props?.modConfirmAction}
            DrugOrderActionSequence={props?.DrugOrderActionSequence}
            medErrorWorking={props?.MedErrorListSequence?.medErrorWorking}
            errorMessage={props?.errorMessage}
            loadingStatus={props?.loadingStatus}
            successMessage={props?.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            drugPermission={props.drugPermission}
            // DrugTransferRequest
            drugTransferRequestSequence={props?.DrugTransferRequestSequence}
            drugOrderHistoryController={props?.drugOrderHistoryController}
            drugRequestList={props?.drugRequestList}
            selectedDevice={props?.selectedDevice}
            drugTransferRequestlist={props?.drugTransferRequestlist}
            drugTransferLog={props?.drugTransferLog}
            drugTransferRequestDetail={props?.drugTransferRequestDetail}
            //ReturnOrder
            ReturnOrderSequence={props.ReturnOrderSequence}
            OrderReturnList={props.OrderReturnList}
            DrugReturnList={props.DrugReturnList}
            OrderHistory={props.OrderHistory}
            OrderReturnLog={props.OrderReturnLog}
            // Allergy
            AllergySequence={props.AllergySequence}
            // StockManagement
            lotNoExpList={props.lotNoExpList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            type={"ALL_HISTORY"} // for flag selectEncounter in function SelectDrugOrderWorking
            languageUX={props.languageUX}
          />
        </div>
      ) : mode === "stock" ? (
        <div style={{ height: "90vh", paddingTop: "5px" }}>
          <TabFramework
            tabWidth="150px"
            // selectedTabIndex={0}
            data={[
              {
                key: "การจัดการสินค้า",
                name: intl.formatMessage({ id: "การจัดการสินค้า" }),
                render: () => (
                  <CardStockManagement
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    // seq
                    runSequence={props.runSequence}
                    StockManagementSequence={props.StockManagementSequence}
                    // data
                    selectedDivision={props.selectedDivision}
                    // CommonInterface
                    buttonLoadCheck={props.buttonLoadCheck}
                    errorMessage={props.errorMessage}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    // options
                    masterOptions={props.masterOptions}
                    masterData={props.masterData}
                    languageUX={props.languageUX}
                  />
                ),
              },
              {
                key: "รายการโอนสินค้า (Transfer request)",
                name: (
                  <div
                    onClick={() => {
                      props.setProp("transferOrderCount", 0);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label>
                      {intl.formatMessage({ id: "รายการโอนสินค้า (Transfer request)" })}
                    </label>
                    {props.transferOrderCount > 0 && (
                      <Label
                        data-testid="msg-noti"
                        circular
                        color={"red"}
                        size="mini"
                        onClick={() => {
                          props.setProp("transferOrderCount", 0);
                        }}
                      >
                        {props.transferOrderCount}
                      </Label>
                    )}
                  </div>
                ),
                render: () => (
                  <CardStockTransferOrder
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    // seq
                    runSequence={props.runSequence}
                    StockTransferOrderSequence={props.StockTransferOrderSequence}
                    masterOptions={props.masterOptions}
                    // CommonInterface
                    buttonLoadCheck={props.buttonLoadCheck}
                    errorMessage={props.errorMessage}
                    selectedDivision={props.selectedDivision}
                    languageUX={props.languageUX}
                  />
                ),
              },
              {
                key: "คิวใบขอยา",
                name: intl.formatMessage({ id: "คิวใบขอยา" }),
                render: () => (
                  <CardDrugTransferRequestQueue
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    runSequence={props.runSequence}
                    drugRequestList={props.drugRequestList}
                    languageUX={props.languageUX}
                    languageUX={props.languageUX}
                  />
                ),
              },
              // {
              //   key: "ขอยา",
              //   name: intl.formatMessage({id: "ขอยา"}),
              //   render: () => (
              //     <CardDrugTransferRequest
              //       onEvent={props.onEvent}
              //       setProp={props.setProp}
              //       // seq
              //       runSequence={props.runSequence}
              //       DrugTransferRequestSequence={
              //         props.drugTransferRequestSequence
              //       }
              //       // options
              //       masterOptions={props.masterOptions}
              //       // controller
              //       controller={props.drugOrderHistoryController}
              //       // data
              //       drugRequestList={props.drugRequestList}
              //       selectedDevice={props.selectedDevice}
              //       selectedDivision={props.selectedDivision}
              //       drugTransferRequestlist={props.drugTransferRequestlist}
              //       drugTransferLog={props.drugTransferLog}
              //       drugTransferRequestDetail={props.drugTransferRequestDetail}
              //       // CommonInterface
              //       errorMessage={props.errorMessage}
              //       successMessage={props.successMessage}
              //     />
              //   ),
              // },
              {
                key: "ประวัติการขอยา",
                name: intl.formatMessage({ id: "ประวัติการขอยา" }),
                render: () => (
                  <CardDrugTransferRequestHistory
                    onEvent={props.onEvent}
                    runSequence={props.runSequence}
                    masterOptions={props.masterOptions}
                    setProp={props.setProp}
                    DrugTransferRequestHistorySequence={props.DrugTransferRequestHistorySequence}
                    DrugTransferRequestHistoryList={props.DrugTransferRequestHistoryList}
                    languageUX={props.languageUX}
                  />
                ),
              },
              {
                key: "ประวัติการขอคืนยา",
                name: intl.formatMessage({ id: "ประวัติการขอคืนยา" }),
                render: () => (
                  <CardDrugReturnRequestHistory
                    onEvent={props.onEvent}
                    runSequence={props.runSequence}
                    masterOptions={props.masterOptions}
                    setProp={props.setProp}
                    controller={props.drugOrderHistoryController}
                    DrugReturnRequestHistorySequence={props.DrugReturnRequestHistorySequence}
                    DrugReturnRequestHistoryList={props.DrugReturnRequestHistoryList}
                    // CommonInterface
                    errorMessage={props.errorMessage}
                    buttonLoadCheck={props.buttonLoadCheck}
                    languageUX={props.languageUX}
                  />
                ),
              },
              {
                key: "จัดการกล่องยากู้ชีพ",
                name: intl.formatMessage({ id: "จัดการกล่องยาสำรอง" }),
                render: () => (
                  <CardDrugResuscitation
                    // function
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    controller={props.drugOrderHistoryController}
                    // CommonInterface
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    loadingStatus={props?.loadingStatus}
                    buttonLoadCheck={props.buttonLoadCheck}
                    masterOptions={props.masterOptions}
                    // seq
                    runSequence={props.runSequence}
                    DrugResuscitationSequence={props.DrugResuscitationSequence}
                    isNurseScreen={false}
                    languageUX={props.languageUX}
                  />
                ),
              },
            ]}
          />
        </div>
      ) : mode === "telaphar" ? (
        <CardTelepharQueue
          runSequence={props.runSequence}
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          forward={props.forward}
          layout={props.layout}
          masterOptions={props.masterOptions}
          selectedDivision={props.selectedDivision}
          TelePharmacySequence={props.TelePharmacySequence}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // Noti
          notificationMessageList={props.notificationMessageList}
          languageUX={props.languageUX}
        />
      ) : mode === "orschedule" ? (
        <CardORQueue
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          forward={props.forward}
          // common
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // seq
          runSequence={props.runSequence}
          ORQueueSequence={props.ORQueueSequence}
          ZoneLogSequence={props.ZoneLogSequence}
          ORRequestSequence={props.ORRequestSequence}
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          proxyController={props.proxyController}
          // SearchBox
          searchedItemList={props.searchedItemList}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          masterOptions={props.masterOptions}
          masterData={props.masterData}
          // data
          selectedOrOrder={props.selectedOrOrder}
          loadingStatus={props.loadingStatus}
          userTokenize={props.userTokenize}
          divisionId={props.divisionId}
          config={{
            showFilterORQueue: false,
            showFilterDrugQueue: true,
          }}
          cardStyle={{ margin: "0 -10px" }}
          languageUX={props.languageUX}
        />
      ) : mode === "print_drug_label" ? (
        <CardPrintDrugLabelManual
          onEvent={props.onEvent}
          setProp={props.setProp}
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          masterOptions={props.masterOptions}
          languageUX={props.languageUX}
        />
      ) : mode === "patienthistoryviewer" ? (
        <div style={{ paddingTop: "1rem", height: "90vh", overflowY: "auto" }}>
          <CardPHVWrapper
            apiToken={props.apiToken}
            division={props.division}
            django={props.django}
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            // controller
            proxyController={props.proxyController}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // options
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            ChoiceTriage={props.ChoiceTriage}
            // seq
            runSequence={props.runSequence}
            AssessmentSequence={props.AssessmentSequence}
            HistoryCentralLabSequence={props.HistoryCentralLabSequence}
            ORPostOperationSequence={props.ORPostOperationSequence}
            ORHistorySequence={props.ORHistorySequence}
            ORRequestSequence={props.ORRequestSequence}
            PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            PreOperationSequence={props.PreOperationSequence}
            // SearchBox
            searchedItemListWithKey={props.searchedItemListWithKey}
            // data
            selectedPatient={props.selectedPatient}
            loadingStatus={props.loadingStatus}
            userTokenize={props.userTokenize}
            notRenameTitle={true}
            languageUX={props.languageUX}
          />
        </div>
      ) : mode === "unverifiedallergy" ? (
        <CardUnverifiedAllergy
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          UnverifiedAllergySequence={props.UnverifiedAllergySequence}
          forward={props?.forward}
          // SearchBox
          searchedItemList={props.searchedItemList}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          languageUX={props.languageUX}
        />
      ) : !!props.MedErrorListSequence?.medErrorStatistics?.open ? (
        <CardMedicationErrorStatistics
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          medErrorStatistics={props.MedErrorListSequence?.medErrorStatistics}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          languageUX={props.languageUX}
        />
      ) : (
        <CardMedicationErrorList
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // options
          masterOptions={props.masterOptions}
          // data
          django={props.django}
          MedErrorListSequence={props.MedErrorListSequence}
          userId={props.userId}
          selectedDivision={props.selectedDivision}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // config
          allowedPrint={true}
          languageUX={props.languageUX}
        />
      )}

      <ModConfirm
        openModal={openModConfirmPrintMedicine}
        titleName={intl.formatMessage({ id: "ข้อความแจ้งเตือน" })}
        titleColor="red"
        size="mini"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        approveButtonText={noHaveRequest ? "" : "ดำเนินการต่อ"}
        basic={false}
        // Style
        approveButtonStyle={{ width: "9rem", marginLeft: "-1rem" }}
        denyButtonStyle={{ ...(!noHaveRequest && { width: "9rem", marginLeft: "-2rem" }) }}
        // callback
        onDeny={handleCloseModConfirmPrintMedicine}
        onCloseWithDimmerClick={handleCloseModConfirmPrintMedicine}
        onApprove={() => handlePrintMedicineForm({ bypassRequest: true })}
        // ELement
        content={
          <div
            style={{
              margin: "0rem 1.25rem -1rem 0.75rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            <div>{intl.formatMessage({ id: "ระบบจะดำเนินการเฉพาะใบสั่งยา" })}</div>
            <div>{intl.formatMessage({ id: "สถานะ REQUESTED เท่านั้น" })}</div>
          </div>
        }
      />
      <ModConfirm
        openModal={props.SetDrugOrderQueueSequence?.drugPendingMedReconcile}
        titleName={intl.formatMessage({ id: "ข้อความแจ้งเตือน" })}
        titleColor="red"
        size="small"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        approveButtonText={
          props.SetDrugOrderQueueSequence?.drugPendingMedReconcile?.ablePrint ? "ดำเนินการต่อ" : ""
        }
        basic={false}
        // Style
        // approveButtonStyle={{ width: "9rem", marginLeft: "-1rem" }}
        // denyButtonStyle={{...(props.SetDrugOrderQueueSequence?.drugPendingMedReconcile?.ablePrint && { width: "9rem", marginLeft: "-2rem" })}}
        // callback
        onDeny={handleClose}
        onCloseWithDimmerClick={handleClose}
        onApprove={handleContinue}
        // ELement
        content={
          <div
            style={{
              margin: "0rem 1.25rem -1rem 0.75rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {props.SetDrugOrderQueueSequence?.drugPendingMedReconcile?.medError?.length > 0 && (
              <>
                {" "}
                <div>
                  {intl.formatMessage({
                    id: "ระบบไม่สามารถ Verify ใบสั่งยาของผู้ป่วย ดังต่อไปนี้ได้",
                  })}
                </div>
                <ul>
                  {" "}
                  {props.SetDrugOrderQueueSequence?.drugPendingMedReconcile?.medError?.map(
                    (i: any) => (
                      <li style={{ textAlign: "left" }}>{i}</li>
                    )
                  )}{" "}
                </ul>
              </>
            )}
            {props.SetDrugOrderQueueSequence?.drugPendingMedReconcile?.otherError?.length > 0 && (
              <>
                <div>{intl.formatMessage({ id: "เกิดข้อผิดพลาดอื่นๆ" })}</div>
                <ul>
                  {props.SetDrugOrderQueueSequence?.drugPendingMedReconcile?.otherError?.map(
                    (i: any) => (
                      <li style={{ textAlign: "left" }}>{i}</li>
                    )
                  )}
                </ul>
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    onClick={(e: any) => props.setMode(props.menu)}
    color={props.menu === props.mode ? "blue" : undefined}
    style={{ position: "relative", paddingLeft: "1rem", paddingRight: "1rem" }}
  >
    {props.name}

    {props.noti && (
      <div
        style={{
          backgroundColor: "red",
          borderRadius: "50%",
          width: "12px",
          height: "12px",
          top: "-4px",
          right: "-2px",
          position: "absolute",
        }}
      ></div>
    )}
  </Button>
);

export default CardPharmacyTask;
