import React, { useCallback, useEffect, useMemo, useRef } from "react";
// UX
import CardUnverifiedAllergyUX from "react-lib/apps/HISV3/TPD/CardUnverifiedAllergyUX";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";

// Types
type CardUnverifiedAllergyProps = {
  onEvent: (e: any) => any;
  setProp: any;
  // seq
  runSequence: any;
  // data
  UnverifiedAllergySequence: any;
  // CommonInterface
  django?: any;
  selectedEmr?: Record<string, any>;
  divisionType?: string | null;
  selectedEncounter?: Record<string, any>;
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  forward?: any;
  // SearchBox
  searchedItemList?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: any;
};

const CARD_UNVERIFIED_ALLERGY = "CardUnverifiedAllergy";

const CardUnverifiedAllergy = (props: CardUnverifiedAllergyProps) => {
  const clickCountRef = useRef(0);
  const clickTimeoutRef = useRef<any>(null);

  useEffect(() => {
    props.runSequence({
      sequence: "UnverifiedAllergy",
      restart: true,
    });
  }, []);

  const unverifiedAllergyData = useMemo(() => {
    return (props.UnverifiedAllergySequence?.unverifiedAllergyList || []).map((item: any) => {
      return {
        ...item,
        createdDate: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {dateToStringWithoutTimeBE(item.created)}
          </div>
        ),
        hnData: <div style={{ display: "flex", justifyContent: "center" }}>{`${item.hn}`}</div>,
        allergy: <div>{item.adversary_info}</div>,
      };
    });
  }, [props.UnverifiedAllergySequence?.unverifiedAllergyList]);

  const handleChangeDate = (type: string) => (date: any) => {
    props.setProp(`UnverifiedAllergySequence.unverifiedAllergyFilter.${type}`, date);
  };

  const handleChangeValue = (e: any, data: any) => {
    const detail: any = props.UnverifiedAllergySequence?.unverifiedAllergyFilter || {};
    let value = typeof data.checked === "boolean" ? data.checked : data.value;

    detail[data.name] = value;

    props.setProp("UnverifiedAllergySequence.unverifiedAllergyFilter", { ...detail });
  };

  const handleSelectedItem = useCallback(
    (data: { id: string; name: string; type: string }) =>
      async (value: any, key: any, obj: any) => {
        let selected = props.searchedItemListWithKey?.[`${data.type}_${data.id}`]?.find(
          (item: any) => item.id === key
        );

        if (!selected?.id) {
          props.setProp(`searchedItemListWithKey.${data.type}_${data.id}`, []);
        }

        handleChangeValue(null, {
          name: data.name,
          value: selected?.id || null,
        });
      },
    [props.searchedItemListWithKey, props.UnverifiedAllergySequence?.unverifiedAllergyFilter]
  );

  const handleSearchData = () => {
    props.runSequence({
      sequence: "UnverifiedAllergy",
      action: "search",
      cardKey: CARD_UNVERIFIED_ALLERGY,
    });
  };

  const handleRowProps = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id === props.UnverifiedAllergySequence?.selectedAllergyOrder?.id
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        clickCountRef.current += 1;

        clickTimeoutRef.current = setTimeout(() => {
          if (clickCountRef.current === 1) {
            props.setProp("UnverifiedAllergySequence.selectedAllergyOrder", rowInfo?.original);
          }
          clickCountRef.current = 0;
        }, 300);
      },
      onDoubleClick: () => {
        clearTimeout(clickTimeoutRef.current);

        props.runSequence({
          sequence: "UnverifiedAllergy",
          action: "selectAllergy",
          cardKey: CARD_UNVERIFIED_ALLERGY,
          selectData: rowInfo?.original,
        });

        props.forward();
        clickTimeoutRef.current = 0;
      },
    };
  };

  return (
    <div>
      <CardUnverifiedAllergyUX
        unverifiedAllergyFilter={props.UnverifiedAllergySequence?.unverifiedAllergyFilter}
        onChangeStartDate={handleChangeDate("startDate")}
        onChangeEndDate={handleChangeDate("endDate")}
        unverifiedAllergyData={unverifiedAllergyData}
        rowProps={handleRowProps}
        searchPatient={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Patient"
            id="UnverifiedAllergy"
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.UnverifiedAllergySequence?.unverifiedAllergyFilter?.patient}
            setSelectedItem={handleSelectedItem({
              id: "UnverifiedAllergy",
              name: "patient",
              type: "Patient",
            })}
            style={{ width: "100%" }}
            dropdownStyle={{ opacity: 1 }}
            isFilterPatient={true}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearchData}
            // data
            paramKey={`${CARD_UNVERIFIED_ALLERGY}_search`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_UNVERIFIED_ALLERGY}_search`]}
            // config
            name="search"
            style={{ width: "100%" }}
            title="ค้นหา"
            color="blue"
          />
        }
      />
    </div>
  );
};

CardUnverifiedAllergy.displayName = "CardUnverifiedAllergy";

export default React.memo(CardUnverifiedAllergy);
