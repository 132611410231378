import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Icon,
  Button,
  Checkbox
} from 'semantic-ui-react'

const CardSubAllergyHistoryUX = (props: any) => {
    return(
      <div
        id="CardSubAllergyHistoryUX-div-0"
        style={{ padding: "0px"}}>
        
        <div
          id="CardSubAllergyHistoryUX-div-2"
          style={{ padding: "2px"}}>
          
          <div
            id="CardSubAllergyHistoryUX-div-13"
            style={{display: "flex", alignItems: "center",color:"rgb(33, 112, 186)", margin: "0rem 0px 0.5rem 1rem", ...(props.modal?{backgroundColor: "#2185d0", margin:"-2px -2px 0px -2px", padding:"0.75rem 1.5rem", color: "white"}:{})}}>
            
            <div
              id="CardSubAllergyHistoryUX-div-14"
              style={{display:props.modal?"none":"none"}}>
              
              <Icon
                className="large"
                id="CardSubAllergyHistoryUX-Icon-74"
                name="angle down">
              </Icon>
            </div>
            <div
              id="CardSubAllergyHistoryUX-div-75"
              style={{display: "flex", marginRight: "0.75rem", alignItems: "center"}}>
              
              <img
                id="CardSubAllergyHistoryUX-img-79"
                src={props.icon}
                style={{width: "24px", height: "100%"}}>
              </img>
              <div
                id="CardSubAllergyHistoryUX-div-78"
                style={{fontWeight: "bold", fontSize: "1.15rem", margin: "0 0.75rem", borderBottom: props.modal?"":"2px solid rgb(33, 112, 186)"}}>
                {`ประวัติการแพ้${props.name}`}
              </div>
            </div>
            <div
              id="CardSubAllergyHistoryUX-div-81"
              style={{margin:"0 1.5rem -0.5rem 0", display: props.modal?"none":""}}>
              
              <Button
                id={`CardSubAllergyHistory-Button-Img-${props.name}`}
                onClick={props.onClickStatusIcon}
                style={{padding:0, backgroundColor:"unset"}}>
                <img
                  id="CardSubAllergyHistoryUX-img-101"
                  src={props.statusLabel?.icon}
                  style={{width: "30px", height: "30px", display: props.statusLabel?.icon?"":"none"}}>
                </img>
              </Button>
            </div>
            <div
              id="CardSubAllergyHistoryUX-div-76"
              style={{display: props.modal?"none":""}}>
              
              <Button
                basic={true}
                color="black"
                id={`CardSubAllergyHistory-Button-Deny-${props.name}`}
                onClick={props.onDenyDrugAllergy}
                size="mini"
                style={{marginTop: "0.15rem", display: props.statusLabel?.icon? "none": ""}}>
                {`ปฏิเสธแพ้${props.name}`}
              </Button>
            </div>
          </div>
          <div
            id="CardSubAllergyHistoryUX-div-97"
            style={{display: props.modal? "flex":"none", alignItems:"center", margin: "1rem 20px"}}>
            
            <img
              id="CardSubAllergyHistory-Img-StatusLabel"
              src={props.statusLabel?.icon}
              style={{width: "30px", height: "100%"}}>
            </img>
            <div
              id="CardSubAllergyHistoryUX-div-99"
              style={{color:props.statusLabel?.color, marginLeft: "1rem"}}>
              {props.statusLabel?.title}
            </div>
            <div
              id="CardSubAllergyHistoryUX-div-103"
              style={{flex:1}}>
              
            </div>
            <Checkbox
              checked={props.showInactive||false}
              id="CardSubAllergyHistory-Checkbox-ShowInactive"
              label="แสดงประวัติ Inactived"
              onChange={props.onShowInactive}
              style={{ marginRight: "1.25rem" }}>
            </Checkbox>
          </div>
          <div
            id="CardSubAllergyHistoryUX-div-23"
            style={{fontWeight: "bold",fontSize:"large", display: props.noReactionDetail?"":"none"}}>
            
            <div
              id="CardSubAllergyHistoryUX-div-26"
              style={{display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop: "1.25rem"}}>
              {}
              <div
                id="CardSubAllergyHistoryUX-div-90">
                
                <div
                  id="CardSubAllergyHistoryUX-div-91"
                  style={{ fontWeight: "bold", fontSize: "large" }}>
                  
                  <div
                    className="ui center aligned container"
                    id={`CardSubAllergyHistoryUX-div-PatientDeny-${props.name}`}
                    style={{ fontSize: "1.25rem" }}>
                    {`ผู้ป่วยปฏิเสธแพ้${props.name} [No Known Allergies]`}
                  </div>
                  <div
                    className="ui center aligned container"
                    id="CardSubAllergyHistoryUX-div-93"
                    style={{ fontSize: "1rem", fontWeight: "normal", marginTop: "1rem", }}>
                    {`ผู้บันทึก : ${props.noReactionDetail?.editUser}`}
                  </div>
                  <div
                    className="ui center aligned container"
                    id="CardSubAllergyHistoryUX-div-94"
                    style={{ fontSize: "1rem", fontWeight: "normal" }}>
                    {`วันที่-เวลา : ${props.noReactionDetail?.edited}`}
                  </div>
                </div>
              </div>
            </div>
            <div
              id="CardSubAllergyHistoryUX-div-72"
              style={{display: props.modal?"none":""}}>
              {}
              <div
                id="CardSubAllergyHistoryUX-div-88"
                style={{ padding: "1.75rem 20px 0" }}>
                
                <div
                  id="CardSubAllergyHistoryUX-div-89"
                  style={{ flex: 1, height: "1px", backgroundColor: "#D8D9D9" }}>
                  
                </div>
              </div>
            </div>
          </div>
          <div
            id="CardSubAllergyHistoryUX-div-24"
            style={{fontWeight: "bold",fontSize:"large", display: props.isNotFound?"":"none"}}>
            
            <div
              id="CardSubAllergyHistoryUX-div-27"
              style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              {}
              <div
                id="CardSubAllergyHistoryUX-div-83"
                style={{ width: "100%", display: "flex", justifyContent: "center", color: "rgb(197, 197, 197)", padding: "1.25rem 0px 0.75rem" }}>
                
                <div
                  id="CardSubAllergyHistoryUX-div-84"
                  style={{ width: "100%", padding: "0 1.5rem", position: "relative", }}>
                  
                  <div
                    id="CardSubAllergyHistoryUX-div-85"
                    style={{ alignItems: "center", width: "100%", border: "1.55px dashed rgb(197, 197, 197)", borderTop: "thin", }}>
                    
                  </div>
                  <div
                    id="CardSubAllergyHistoryUX-div-86"
                    style={{ position: "absolute", width: "100%", display: "flex", justifyContent: "center", top: "-10px",left: 0 }}>
                    
                    <div
                      id={`CardSubAllergyHistory-div-NoDetail-${props.name}`}
                      style={{ backgroundColor: "white", width: "fit-content", padding: "0 5px", fontWeight: "bold", fontSize: "1.25rem"}}>
                      ไม่มีข้อมูลการบันทึก
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="CardSubAllergyHistoryUX-div-73">
              {}
              <div
                id="CardSubAllergyHistoryUX-div-95"
                style={{ padding: "1.75rem 20px 0" }}>
                
                <div
                  id="CardSubAllergyHistoryUX-div-96"
                  style={{ flex: 1, height: "1px", backgroundColor: "#D8D9D9" }}>
                  
                </div>
              </div>
            </div>
          </div>
          <div
            id="CardSubAllergyHistoryUX-div-25"
            style={{ padding: "0px 20px 0px 20px", display: props.data?.length === 0 ? "none" : "" }}>
            
            <Table
              className="--rt-tbody-overflow-y-hidden"
              data={props.data}
              getTrProps={props.onGetTrProps}
              headers={props.headers}
              id={`CardSubAllergyHistory-Table-${props.name}`}
              keys={props.keys}
              minRows={props.modal?"1":"3"}
              showPagination={false}
              style={props.modal?{maxHeight: "75vh"}:{height: "200px"}}
              widths={props.widths}>
            </Table>
            <div
              className="allergy-list-pagination"
              id="CardSubAllergyHistoryUX-div-29"
              style={{paddingTop: "0.5rem", display: "flex", justifyContent: "flex-end"}}>
              { props.paginationComponent}
            </div>
          </div>
        </div>
        <div
          id="CardSubAllergyHistoryUX-div-67"
          style={{width: "100%", height: "30px"}}>
          
        </div>
      </div>
    )
}


export default CardSubAllergyHistoryUX

export const screenPropsDefault = {}

/* Date Time : Fri Feb 28 2025 10:56:46 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-2"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"2px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-13"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",color:\"rgb(33, 112, 186)\", margin: \"0rem 0px 0.5rem 1rem\", ...(props.modal?{backgroundColor: \"#2185d0\", margin:\"-2px -2px 0px -2px\", padding:\"0.75rem 1.5rem\", color: \"white\"}:{})}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-14"
        },
        "style": {
          "type": "code",
          "value": "{display:props.modal?\"none\":\"none\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-23"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\", display: props.noReactionDetail?\"\":\"none\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-24"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\", display: props.isNotFound?\"\":\"none\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-25"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 20px 0px 20px\", display: props.data?.length === 0 ? \"none\" : \"\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-26"
        },
        "style": {
          "type": "code",
          "value": "{display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop: \"1.25rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-27"
        },
        "style": {
          "type": "code",
          "value": "{display: 'flex',alignItems: 'center',justifyContent: 'center'}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 28,
      "name": "Table",
      "parent": 25,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headers"
        },
        "id": {
          "type": "code",
          "value": "`CardSubAllergyHistory-Table-${props.name}`"
        },
        "keys": {
          "type": "code",
          "value": "props.keys"
        },
        "minRows": {
          "type": "code",
          "value": "props.modal?\"1\":\"3\""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "props.modal?{maxHeight: \"75vh\"}:{height: \"200px\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.widths"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": " props.paginationComponent"
        },
        "className": {
          "type": "value",
          "value": "allergy-list-pagination"
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-29"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"0.5rem\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-67"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"30px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-72"
        },
        "style": {
          "type": "code",
          "value": "{display: props.modal?\"none\":\"\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-73"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Icon",
      "parent": 14,
      "props": {
        "className": {
          "type": "value",
          "value": "large"
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-Icon-74"
        },
        "name": {
          "type": "value",
          "value": "angle down"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-75"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", marginRight: \"0.75rem\", alignItems: \"center\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-76"
        },
        "style": {
          "type": "code",
          "value": "{display: props.modal?\"none\":\"\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "code",
          "value": "`ประวัติการแพ้${props.name}`"
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-78"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.15rem\", margin: \"0 0.75rem\", borderBottom: props.modal?\"\":\"2px solid rgb(33, 112, 186)\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "img",
      "parent": 75,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-img-79"
        },
        "src": {
          "type": "code",
          "value": "props.icon"
        },
        "style": {
          "type": "code",
          "value": "{width: \"24px\", height: \"100%\"}"
        }
      },
      "seq": 78,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Button",
      "parent": 76,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "code",
          "value": "`ปฏิเสธแพ้${props.name}`"
        },
        "color": {
          "type": "value",
          "value": "black"
        },
        "id": {
          "type": "code",
          "value": "`CardSubAllergyHistory-Button-Deny-${props.name}`"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDenyDrugAllergy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.15rem\", display: props.statusLabel?.icon? \"none\": \"\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-81"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"0 1.5rem -0.5rem 0\", display: props.modal?\"none\":\"\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-83"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", justifyContent: \"center\", color: \"rgb(197, 197, 197)\", padding: \"1.25rem 0px 0.75rem\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-84"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"0 1.5rem\", position: \"relative\", }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-85"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"center\", width: \"100%\", border: \"1.55px dashed rgb(197, 197, 197)\", borderTop: \"thin\", }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-86"
        },
        "style": {
          "type": "code",
          "value": "{ position: \"absolute\", width: \"100%\", display: \"flex\", justifyContent: \"center\", top: \"-10px\",left: 0 }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่มีข้อมูลการบันทึก"
        },
        "id": {
          "type": "code",
          "value": "`CardSubAllergyHistory-div-NoDetail-${props.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"white\", width: \"fit-content\", padding: \"0 5px\", fontWeight: \"bold\", fontSize: \"1.25rem\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-88"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"1.75rem 20px 0\" }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-89"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1, height: \"1px\", backgroundColor: \"#D8D9D9\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-90"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-91"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"large\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "code",
          "value": "`ผู้ป่วยปฏิเสธแพ้${props.name} [No Known Allergies]`"
        },
        "className": {
          "type": "value",
          "value": "ui center aligned container"
        },
        "id": {
          "type": "code",
          "value": "`CardSubAllergyHistoryUX-div-PatientDeny-${props.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.25rem\" }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "code",
          "value": "`ผู้บันทึก : ${props.noReactionDetail?.editUser}`"
        },
        "className": {
          "type": "value",
          "value": "ui center aligned container"
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-93"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1rem\", fontWeight: \"normal\", marginTop: \"1rem\", }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "code",
          "value": "`วันที่-เวลา : ${props.noReactionDetail?.edited}`"
        },
        "className": {
          "type": "value",
          "value": "ui center aligned container"
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-94"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1rem\", fontWeight: \"normal\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-95"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"1.75rem 20px 0\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-96"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1, height: \"1px\", backgroundColor: \"#D8D9D9\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-97"
        },
        "style": {
          "type": "code",
          "value": "{display: props.modal? \"flex\":\"none\", alignItems:\"center\", margin: \"1rem 20px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "img",
      "parent": 97,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistory-Img-StatusLabel"
        },
        "src": {
          "type": "code",
          "value": "props.statusLabel?.icon"
        },
        "style": {
          "type": "code",
          "value": "{width: \"30px\", height: \"100%\"}"
        }
      },
      "seq": 98,
      "void": true
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusLabel?.title"
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-99"
        },
        "style": {
          "type": "code",
          "value": "{color:props.statusLabel?.color, marginLeft: \"1rem\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Button",
      "parent": 81,
      "props": {
        "id": {
          "type": "code",
          "value": "`CardSubAllergyHistory-Button-Img-${props.name}`"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickStatusIcon"
        },
        "style": {
          "type": "code",
          "value": "{padding:0, backgroundColor:\"unset\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "img",
      "parent": 100,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-img-101"
        },
        "src": {
          "type": "code",
          "value": "props.statusLabel?.icon"
        },
        "style": {
          "type": "code",
          "value": "{width: \"30px\", height: \"30px\", display: props.statusLabel?.icon?\"\":\"none\"}"
        }
      },
      "seq": 101,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Checkbox",
      "parent": 97,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.showInactive||false"
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistory-Checkbox-ShowInactive"
        },
        "label": {
          "type": "value",
          "value": "แสดงประวัติ Inactived"
        },
        "onChange": {
          "type": "code",
          "value": "props.onShowInactive"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1.25rem\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSubAllergyHistoryUX-div-103"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 102,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardSubAllergyHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
