import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Form,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardUnverifiedAllergyUX = (props: any) => {
    return(
      <div
        style={{padding: "1rem 5px"}}>
        <div
          id="CardUnverifiedAllergyUX-div-1">
          
          <Form
            id="CardUnverifiedAllergyUX-Form-4">
            <FormGroup
              id="CardUnverifiedAllergyUX-FormGroup-5"
              inline={true}>
              <FormField
                id="CardUnverifiedAllergyUX-FormField-6"
                inline={true}
                style={{ width: "100%" }}
                width={2}>
                <div
                  id="CardUnverifiedAllergyUX-div-15"
                  style={{ width: "100%" }}>
                  
                  <label
                    id="CardUnverifiedAllergyUX-label-25">
                    Created date
                  </label>
                  <DateTextBox
                    id="CardUnverifiedAllergyUX-DateTextBox-16"
                    onChange={props.onChangeStartDate}
                    value={props.unverifiedAllergyFilter?.startDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                id="CardUnverifiedAllergyUX-FormField-7"
                inline={true}>
                <label
                  id="CardUnverifiedAllergyUX-label-26"
                  style={{ marginTop: "15px" }}>
                  to
                </label>
              </FormField>
              <FormField
                id="CardUnverifiedAllergyUX-FormField-8"
                inline={true}
                width={2}>
                {}
                <div
                  id="CardUnverifiedAllergyUX-div-13"
                  style={{ width: "100%" }}>
                  
                  <label
                    id="CardUnverifiedAllergyUX-label-23">
                    {"\u00a0"}
                  </label>
                  <DateTextBox
                    id="CardUnverifiedAllergyUX-DateTextBox-17"
                    onChange={props.onChangeEndDate}
                    value={props.unverifiedAllergyFilter?.endDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                id="CardUnverifiedAllergyUX-FormField-9"
                inline={true}
                width={4}>
                <div
                  id="CardUnverifiedAllergyUX-div-12"
                  style={{ width: "100%" }}>
                  
                  <label
                    id="CardUnverifiedAllergyUX-label-22">
                    Patient
                  </label>
                  <div
                    id="CardUnverifiedAllergyUX-div-27">
                    {props.searchPatient}
                  </div>
                </div>
              </FormField>
              <FormField
                id="CardUnverifiedAllergyUX-FormField-10"
                inline={true}>
                <div
                  id="CardUnverifiedAllergyUX-div-11"
                  style={{ width: "100%" }}>
                  
                  <label
                    id="CardUnverifiedAllergyUX-label-21">
                    {"\u00a0"}
                  </label>
                  <div
                    id="CardUnverifiedAllergyUX-div-28">
                    {props.buttonSearch}
                  </div>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          id="CardUnverifiedAllergyUX-div-2">
          
          <Table
            data={props.unverifiedAllergyData}
            getTrProps={props.rowProps}
            headers={"Created date,HN,Patient,รายการยาที่แพ้,Created by"}
            id="CardUnverifiedAllergyUX-Table-3"
            keys="createdDate,hnData,patient_name,allergy,created_user_name"
            minRows="15"
            showPagination={false}
            style={{ maxHeight: "95vh" }}
            widths="180,240,*,*,*">
          </Table>
        </div>
      </div>
    )
}


export default CardUnverifiedAllergyUX

export const screenPropsDefault = {"userSearch":"[User Search Box]"}

/* Date Time : Wed Mar 26 2025 10:40:37 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1rem 5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-div-1"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-div-2"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 3,
      "name": "Table",
      "parent": 2,
      "props": {
        "data": {
          "type": "code",
          "value": "props.unverifiedAllergyData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "\"Created date,HN,Patient,รายการยาที่แพ้,Created by\""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-Table-3"
        },
        "keys": {
          "type": "value",
          "value": "createdDate,hnData,patient_name,allergy,created_user_name"
        },
        "minRows": {
          "type": "value",
          "value": "15"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ maxHeight: \"95vh\" }"
        },
        "widths": {
          "type": "value",
          "value": "180,240,*,*,*"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Form",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-Form-4"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-FormGroup-5"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-FormField-6"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-FormField-7"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-FormField-8"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-FormField-9"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-FormField-10"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-div-11"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-div-12"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-div-13"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-div-15"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 16,
      "name": "DateTextBox",
      "parent": 15,
      "props": {
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-DateTextBox-16"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.unverifiedAllergyFilter?.startDate"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 17,
      "name": "DateTextBox",
      "parent": 13,
      "props": {
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-DateTextBox-17"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.unverifiedAllergyFilter?.endDate"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-label-21"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient"
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-label-22"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-label-23"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Created date"
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-label-25"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "to"
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-label-26"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"15px\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchPatient"
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-div-27"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "id": {
          "type": "value",
          "value": "CardUnverifiedAllergyUX-div-28"
        }
      },
      "seq": 28,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardUnverifiedAllergyUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "userSearch": "[User Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
