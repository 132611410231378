import React, { useMemo, useCallback, SyntheticEvent, useState, useEffect } from "react";
import {
  Button,
  CheckboxProps,
  Form,
  Image,
  List,
  Modal,
  Pagination,
  PaginationProps,
  Popup,
  TextArea,
} from "semantic-ui-react";

import moment from "moment";

// UX
import CardSubAllergyHistoryUX from "./CardSubAllergyHistoryUX";
import CardAllergyActionLog from "./CardAllergyActionLog";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

// Utils
import { ADR_TYPES } from "react-lib/apps/HISV3/TPD/sequence/Allergy";
import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// Types
type CardSubAllergyHistoryProps = {
  onEvent?: (e: any) => any;
  setProp?: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  AllergySequence?: any;
  // data
  type: AllergyType;
  ardList: any[];
  DJANGO?: any;
  // options
  masterOptions?: any;
  // CommonInterface
  successMessage?: any;
  errorMessage?: any;
  // config
  modal?: boolean; // * Modal เปิดประวัติการแพ้
  // callback
  onAllergyHistory?: (type: AllergyType) => any;
  onShowInactive?: (e: any, data: CheckboxProps) => any;
  onDataUpdated?: () => void;
};

type AllergyType = "drug" | "food" | "other";

// Images
export const IMAGES = {
  //drug
  drug: "/static/images/Icon_drug.png",
  drug_yellow: "/static/images/allergy/Drug(Yellow).png",
  drug_gray: "/static/images/allergy/Drug(Gray).png",
  drug_white: "/static/images/allergy/Drug_white.png",
  drug_red: "/static/images/allergy/Drug(Red).png",
  //food
  food: "/static/images/allergy/Icon_food.png",
  food_gray: "/static/images/allergy/Food(Gray).png",
  food_red: "/static/images/allergy/Food(Red).png",
  food_white: "/static/images/allergy/Food_white.png",
  //other
  other: "/static/images/allergy/Icon_other.png",
  other_gray: "/static/images/allergy/Other(Gray).png",
  other_red: "/static/images/allergy/Other(Red).png",
  other_white: "/static/images/allergy/other_chemistry.png",
  // verify
  noted: "/static/images/allergy/Note_Status.png",
  verified: "/static/images/allergy/Verified_Status.png",
  locked: "/static/images/allergy/Verified_Lock_Status.png",
  inactive: "/static/images/allergy/Inactive_Status.png",
};

// Const
const ADR_TITLES = {
  grey: { drug: "ปฏิเสธแพ้ยา", food: "ปฏิเสธแพ้อาหาร", other: "ปฏิเสธแพ้สารอื่นๆ" },
  red: { drug: "มีประวัติแพ้ยา", food: "มีประวัติแพ้อาหาร", other: "มีประวัติแพ้สารอื่นๆ" },
  yellow: { drug: "มีประวัติแพ้ยา" },
};

const STATUS_LABEL = {
  grey: { color: "rgba(0,0,0,.87)", icon: "gray" },
  red: { color: "red", icon: "red" },
  yellow: { color: "#F3AD25", icon: "yellow" },
};

export const getFoodNOtherStatus = (items: any[] = [], type: "food" | "other" = "food") => {
  let status = { color: "", icon: "", title: "" };

  if (items.length) {
    // * ปฏิเสธการแพ้
    if (items.every((item) => item.status === ADR_STATUS.NO_REACTION)) {
      status = { ...STATUS_LABEL.grey, title: ADR_TITLES.grey[type] };
    } else {
      status = { ...STATUS_LABEL.red, title: ADR_TITLES.red[type] };
    }
  }

  return {
    ...status,
    icon: status.icon && (IMAGES as any)?.[`${type}_${status.icon}`],
  };
};

export const getDrugStatus = (items: any[] = []) => {
  let status = { icon: "", title: "", color: "" };
  let itemReaction = items.filter((data: any) => data.status !== ADR_STATUS.NO_REACTION);

  if (items.length) {
    // * ปฏิเสธการแพ้
    if (items.every((item) => item.status === ADR_STATUS.NO_REACTION)) {
      status = { ...STATUS_LABEL.grey, title: ADR_TITLES.grey.drug };
    } else if (itemReaction.every((item) => item.is_acknowledged)) {
      status = { ...STATUS_LABEL.red, title: ADR_TITLES.red.drug };
    } else {
      status = { ...STATUS_LABEL.yellow, title: ADR_TITLES.yellow.drug };
    }
  }

  return {
    ...status,
    icon: status.icon && (IMAGES as any)?.[`drug_${status.icon}`],
  };
};

export const ADR_STATUS = {
  NO_REACTION: "NO_REACTION",
  NOTED: "NOTED",
  LOCKED: "LOCKED",
};

const ALLERGIES_HISTORY = {
  drug: {
    icon: IMAGES.drug,
    name: "ยา",
    headers: ",ชื่อ, ประเภท, อาการ,ความรุนแรง,ความเป็นไปได้,ข้อความแจ้งเตือน,วันที่,ผู้บันทึก,",
    keys: "status,name, adversary_symptom_type,symptoms,severity_name,probability,note,edited,edit_user,button",
    widths: "60,^165,^100,^100,^100,110,^135,^100,^100,^100",
    modal: {
      icon: IMAGES.drug_white,
      headers: ",ชื่อ,อาการ,ความรุนแรง,ข้อความแจ้งเตือน,ผู้บันทึก,วันที่-เวลา",
      keys: "status,name,symptoms,severity_name,note,edit_user,edited",
      widths: "55,^200,^100,^100,^135,^100,^100",
    },
    getStatus: getDrugStatus,
  },
  food: {
    icon: IMAGES.food,
    name: "อาหาร",
    headers: "รายละเอียดการแพ้,อาการ,วันที่,ผู้บันทึก,",
    keys: "name,note,edited,edit_user,button",
    widths: "^100,^100,^100,^100,120",
    modal: {
      icon: IMAGES.food_white,
      headers: "ชื่อ,อาการ,ผู้บันทึก,วันที่-เวลา",
      keys: "name,note,edit_user,edited",
      widths: "^250,^150,^100,^100",
    },
    getStatus: (items: any[]) => getFoodNOtherStatus(items, "food"),
  },
  other: {
    icon: IMAGES.other,
    name: "สารอื่นๆ",
    headers: "รายละเอียดการแพ้,อาการ,วันที่,ผู้บันทึก,",
    keys: "name,note,edited,edit_user,button",
    widths: "^100,^100,^100,^100,120",
    modal: {
      icon: IMAGES.other_white,
      headers: "ชื่อ,อาการ,ผู้บันทึก,วันที่-เวลา",
      keys: "name,note,edit_user,edited",
      widths: "^250,^150,^100,^100",
    },
    getStatus: (items: any[]) => getFoodNOtherStatus(items, "other"),
  },
};

const CARD_ALLERGY = "CardAllergy";

const CardSubAllergyHistory = (props: CardSubAllergyHistoryProps) => {
  const intl = useIntl();
  const [modConfirm, setModConfirm] = useState<any>(null);
  const [modCancelADR, setModCancelADR] = useState<any>(null);
  const [modActionLog, setModActionLog] = useState<any>(null);

  // Effect
  // * Close ModCancelAdr
  useEffect(() => {
    if (props?.successMessage?.[CARD_ALLERGY] === "Success" && modCancelADR) {
      setModCancelADR(null);

      props.setProp?.("successMessage", {
        ...props.successMessage,
        [CARD_ALLERGY]: null,
      });

      props.setProp?.("errorMessage", {
        ...props.errorMessage,
        [CARD_ALLERGY]: null,
      });
    }
  }, [props?.successMessage]);

  // callback
  const handleEditADR = useCallback((data: any) => {
    props.runSequence({
      sequence: "Allergy",
      action: "selectedADR",
      original: data,
    });
  }, []);

  const handleOpenModCancelADR = useCallback((data: any) => {
    setModCancelADR(data);
  }, []);

  const handleOpenModActionLog = useCallback((data: any) => {
    setModActionLog(data);
  }, []);

  const handleGetTrProps = useCallback(
    (_state: any, rowInfo: any) => {
      const selectedId = props.AllergySequence?.adrSelected?.id;

      return {
        className: selectedId && selectedId === rowInfo?.original?.id ? "blueSelectedRow" : "",
      };
    },
    [props.AllergySequence?.adrSelected]
  );

  const formatADR = useCallback(
    (adrList: any[]) => {
      return adrList.map((item: any) => {
        const typeTemplates = {
          PRODUCT: "Product",
          INGREDIENT: "Ingredient",
          MIMS: "MIMS Group",
          ATC: "ATC Group",
          // OTHER: "FreeText",
        } as any;

        const typeDescriptions = {
          ALLERGY: "แพ้ยา",
          SIDE_EFFECT: "มีอาการข้างเคียง",
          // Add more types if needed
        } as any;

        const symptomsText =
          item.adversary_type !== "OTHER" &&
          item.symptoms
            .map((sym: any) => {
              return props.masterOptions?.ADRSymptoms?.find((opt: any) => opt.key === sym)?.text;
            })
            .join(", ");

        const probabilityText = props.masterOptions?.ADRProbability?.find(
          (opt: any) => opt.key === item.probability
        )?.text;

        return {
          ...item,
          status: <RenderStatusPopup data={item} />,
          name: (
            <>
              {typeTemplates[item.adversary_type]
                ? `${item.name} (${typeTemplates[item.adversary_type]})`
                : item.name}
            </>
          ),
          adversary_symptom_type: (
            <>
              {item.adversary_type !== "OTHER" && typeDescriptions[item.adversary_symptom_type]
                ? typeDescriptions[item.adversary_symptom_type]
                : ""}
            </>
          ),
          symptoms: (
            <>
              {/*  freetext ไม่ต้องแสดงวันที่เกิดอาการ */}
              <div>{symptomsText}</div>
              {item.adversary_type !== "OTHER" && item?.issue_date && (
                <div>วันที่เกิดอาการ: {formatDate(moment(item?.issue_date, "YYYY-MM-DD"))}</div>
              )}
              {item.other_symptom && `Other : ${item.other_symptom}`}
            </>
          ),
          // severity_name,
          probability: probabilityText,
          button: (
            <RenderButtonAction
              data={item}
              DJANGO={props.DJANGO}
              // callback
              onEdit={handleEditADR}
              onCancel={handleOpenModCancelADR}
              onActionLog={handleOpenModActionLog}
            />
          ),
        };
      });
    },
    [props.masterOptions, props.DJANGO]
  );

  const data = useMemo(() => {
    let data = ALLERGIES_HISTORY[props.type];

    if (props.modal) {
      data = { ...data, ...data.modal };
    }

    return data;
  }, [props.type, props.modal]);

  const statusLabel = useMemo(() => {
    return data.getStatus(props.ardList.filter((item) => !item.is_inactive));
  }, [props.ardList, data]);

  const dataWithoutNoReaction = useMemo(() => {
    return props.ardList.filter((item: any) => item.status !== ADR_STATUS.NO_REACTION);
  }, [props.ardList]);

  const noReactionDetail = useMemo(() => {
    // if (props.AllergySequence?.showInactive) {
    //   return null;
    // }
    return statusLabel.icon.includes("Gray")
      ? {
          editUser: props.ardList[0].edit_user,
          divisionName: "",
          edited: props.ardList[0].edited,
        }
      : null;
  }, [statusLabel, props.ardList, props.DJANGO]);

  const actionLogData = useMemo(
    () =>
      props.AllergySequence?.actionLog?.items
        ?.filter((item: any) => item?.remark !== "")
        .map((item: any) => ({
          ...item,
          datetime: formatDatetime(item.datetime),
        })),
    [props.AllergySequence?.actionLog]
  );

  // Handler
  const handleOpenModConfirm = (data: any) => () => {
    setModConfirm(data);
  };

  const handlePageChange = (_e: SyntheticEvent, { activePage }: PaginationProps) => {
    if (props.AllergySequence?.adrActivePage !== activePage) {
      // Fetch data
      props.runSequence({
        sequence: "Allergy",
        action: "selectedPage",
        toActivePage: activePage,
      });
    }
  };

  const handleCloseModCancelADR = () => {
    setModCancelADR(null);

    props.runSequence({
      sequence: "Allergy",
      action: "clearNote",
      card: CARD_ALLERGY,
    });
  };

  const handleConfirmCancelADR = () => {
    props.runSequence({
      sequence: "Allergy",
      action: "actionButton",
      card: CARD_ALLERGY,
      cancel: true,
      item: modCancelADR,
      onDataUpdated: props.onDataUpdated,
    });
  };

  const handleCloseModConfirm = () => {
    setModConfirm(null);
  };

  const handleConfirm = () => {
    props.onEvent?.({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "NO_REACTION",
        type: modConfirm.key,
      },
    });

    handleCloseModConfirm();
  };

  const handleOpenModAllergyHistory = () => {
    props.onAllergyHistory?.(props.type);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <CardSubAllergyHistoryUX
        icon={data.icon}
        name={data.name}
        noReactionDetail={noReactionDetail}
        isNotFound={dataWithoutNoReaction?.length === 0 && !noReactionDetail}
        statusLabel={statusLabel}
        showInactive={props.AllergySequence?.showInactive}
        // table
        data={formatADR(dataWithoutNoReaction)}
        headers={data.headers}
        keys={data.keys}
        widths={data.widths}
        // config
        modal={props.modal}
        // callback
        onDenyDrugAllergy={handleOpenModConfirm({
          ...data,
          key: props.type,
        })}
        onClickStatusIcon={handleOpenModAllergyHistory}
        onShowInactive={props.onShowInactive}
        onGetTrProps={handleGetTrProps}
        // Element
        paginationComponent={
          !!props.AllergySequence?.adrTotalPage && !props.modal ? (
            <Pagination
              size="mini"
              boundaryRange={0}
              ellipsisItem={null}
              siblingRange={1}
              disabled={props?.AllergySequence?.loadingADRTable}
              activePage={props.AllergySequence?.adrActivePage || 1}
              totalPages={props.AllergySequence?.adrTotalPage}
              style={{ transform: "scale(0.9)" }}
              onPageChange={handlePageChange}
            />
          ) : (
            <div style={{ marginBottom: props.modal ? "-1.25rem" : "" }}></div>
          )
        }
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={!!modConfirm}
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}
        titleColor={"blue"}
        content={
          <div style={{ margin: "0 0 -1rem", textAlign: "center" }}>
            ยืนยันการบันทึก "ผู้ป่วยไม่แพ้{modConfirm?.name} (NKA)"
          </div>
        }
        onApprove={handleConfirm}
        denyButtonColor="red"
        approveButtonColor="blue"
        basic={false}
        onDeny={handleCloseModConfirm}
      />

      <ModConfirm
        openModal={!!modCancelADR}
        titleName={intl.formatMessage({ id: "กรอกข้อมูลที่ต้องการบันทึกเพิ่มเติม" })}
        titleColor={"blue"}
        content={
          <Form style={{ margin: "-1rem 0px -3rem" }}>
            <div> Note </div>
            <div style={{ height: "100px" }}>
              <TextArea
                style={{ marginTop: "5px" }}
                value={props.AllergySequence?.actionNote}
                onChange={(_e, v) => {
                  props.setProp?.("AllergySequence.actionNote", v.value);
                }}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <ErrorMessage
                error={props?.errorMessage?.[CARD_ALLERGY]}
                style={{ margin: "-1rem 0 1.5rem" }}
              />
            </div>
          </Form>
        }
        onApprove={handleConfirmCancelADR}
        denyButtonColor="red"
        approveButtonColor="green"
        onDeny={handleCloseModCancelADR}
      />

      <Modal open={!!modActionLog}>
        <CardAllergyActionLog
          id={modActionLog?.id}
          runSequence={props.runSequence}
          actionLogData={actionLogData}
          onDismiss={() => {
            setModActionLog(null);
          }}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                   RenderStatusPopup;                   */

/* ------------------------------------------------------ */
const RenderStatusPopup = (props: any) => {
  const config = useMemo(() => {
    let statusName: string = props.data.status || "";

    if (props.data.is_inactive) {
      statusName = "inactive";
    } else if (props.data.status === ADR_STATUS.NOTED && props.data.is_acknowledged) {
      statusName = "verified";
    }

    const icon = statusName.toLowerCase();

    const titles = {
      inactive: "INACTIVE",
      locked: "VERIFIED & LOCKED (ห้ามสั่งยา)",
      verified: "VERIFIED (สั่งยาได้)",
    } as any;

    const styles = {
      inactive: { width: 31, height: 31 },
      locked: { width: 42, height: 34 },
      verified: { width: 27, height: 27 },
      noted: { width: 32, height: 32 },
    } as any;

    return {
      icon: (IMAGES as any)[icon],
      title: titles[icon],
      style: styles[icon],
    };
  }, [props.data]);

  return (
    <Popup
      content={
        <div>
          {!config.title ? (
            ADR_STATUS.NOTED
          ) : (
            <List>
              <List.Item>{config.title}</List.Item>
              <List.Item>{props.data.edit_user}</List.Item>
              <List.Item>{props.data.edited}</List.Item>
            </List>
          )}
        </div>
      }
      trigger={<Image src={config.icon} style={config.style} />}
      position="left center"
    />
  );
};

/* ------------------------------------------------------ */

/*                   RenderButtonAction;                  */

/* ------------------------------------------------------ */
const RenderButtonAction = (props: any) => {
  const allowedCancel = useMemo(() => {
    if (props.data.type_name_name === ADR_TYPES.DRUG) {
      return (
        // PHARMACIST show all delete
        props.DJANGO?.user?.role_types.includes("PHARMACIST")
          ? true
          : // !PHARMACIST status note show delete
            !props.DJANGO?.user?.role_types.includes("PHARMACIST") &&
              props.data.status === ADR_STATUS.NOTED &&
              !props.data?.is_acknowledged &&
              !props.data?.is_inactive
      );
    } else {
      return true;
    }
  }, [props.data, props.DJANGO]);

  return (
    <div style={{ paddingLeft: "0.5rem" }}>
      <Popup
        content={"EDIT"}
        trigger={
          <Button
            style={{
              display: props.DJANGO?.user?.role_types.includes("PHARMACIST")
                ? "inline-block"
                : "none",
            }}
            size="mini"
            icon="edit"
            color="yellow"
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();

              props.onEdit?.(props.data);
            }}
          />
        }
      />
      {props.data.status !== "NO_REACTION" ? (
        <Popup
          content={"CANCEL"}
          trigger={
            <Button
              style={{
                display: allowedCancel ? "inline-block" : "none",
              }}
              size="mini"
              icon="trash alternate"
              color="red"
              onClick={(event: SyntheticEvent) => {
                event.stopPropagation();

                props.onCancel?.(props.data);
              }}
            />
          }
        />
      ) : null}

      <Button
        size="mini"
        icon="history"
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();

          props.onActionLog?.(props.data);
        }}
      />
    </div>
  );
};

export default React.memo(CardSubAllergyHistory);
